.mobile-nav-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.mobile-nav-navlink {
  text-decoration: none;
}
.mobile-nav-navlink1 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.mobile-nav-navlink2 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.mobile-nav-navlink3 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.mobile-nav-navlink4 {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.mobile-nav-link {
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
































@media(max-width: 767px) {
  .mobile-nav-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .mobile-nav-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }
  .mobile-nav-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .mobile-nav-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .mobile-nav-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .mobile-nav-navlink4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .mobile-nav-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
