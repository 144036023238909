.login-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 85.00%,rgb(20, 67, 114) 85.00%); */
}
.login-adminlogin {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.login-text {
  align-self: center;
}
.login-container1 {
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: space-around;
}
.login-text03 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.login-textinput {
  align-self: stretch;
}
.login-container2 {
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-self: center;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: space-around;
}
.login-text06 {
  align-self: center;
  margin-right: var(--dl-space-space-unit);
}
.login-textinput1 {
  align-self: stretch;
}
.login-navlink {
  width: auto;
  height: auto;
  align-self: center;
  margin-top: var(--dl-space-space-unit);
  border-radius: 10px;
  text-decoration: none;
}
@media(max-width: 991px) {
  .login-adminlogin {
    align-items: center;
  }
  .login-container1 {
    flex-direction: column;
  }
  .login-container2 {
    flex-direction: column;
  }
}
@media(max-width: 767px) {
  .login-adminlogin {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .login-container1 {
    width: var(--dl-size-size-xxlarge);
    flex-direction: column;
  }
  .login-container2 {
    width: var(--dl-size-size-xxlarge);
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .login-adminlogin {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .login-adminlogin {
    margin-top: var(--dl-space-space-fiveunits);
  }
}
