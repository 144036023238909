.image-container {
    position: relative;
    display: inline-block;
}

.img-bg {
    border-radius: 10px;
    filter: brightness(100%);
}

.hover-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    opacity: 0;
    transition: opacity 0.3s;
}

.image-container:hover .hover-icon {
    opacity: 1;
}

.image-container:hover .img-bg {
    filter: brightness(50%);
}