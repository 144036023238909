.icon-social-icon-group {
  width: auto;
  position: relative;
}
.icon-social-container {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.icon-social-link {
  display: contents;
}
.icon-social-image {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.icon-social-link1 {
  display: contents;
}
.icon-social-image1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.icon-social-link2 {
  display: contents;
}
.icon-social-image2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  object-fit: cover;
  margin-right: var(--dl-space-space-unit);
  text-decoration: none;
}
.icon-social-link3 {
  display: contents;
}
.icon-social-image3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  object-fit: cover;
  text-decoration: none;
}





























@media(max-width: 479px) {
  .icon-social-icon-group {
    display: none;
  }
}
@media(max-width: 390px) {
  .icon-social-root-class-name3 {
    display: flex;
  }
  .icon-social-root-class-name4 {
    display: flex;
  }
}
