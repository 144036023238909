.herbarium-advancesearch-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}
.herbarium-advancesearch-hero {
  width: 100%;
  height: 494px;
  display: flex;
  position: relative;
  min-height: auto;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("/playground_assets/herbbg-1500w.jpg");
  background-repeat: no-repeat;
  background-position: top;
}
.herbarium-advancesearch-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.herbarium-advancesearch-text {
  color: rgb(255, 200, 67);
  font-size: 3rem;
  max-width: auto;
  align-self: flex-start;
  margin-left: var(--dl-space-space-twounits);
}
.herbarium-advancesearch-text3 {
  color: rgb(255, 255, 255);
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.herbarium-advancesearch-search-tab {
  width: 100%;
  height: 501px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.herbarium-advancesearch-herbarium-list {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
@media(max-width: 1400px) {
  .herbarium-advancesearch-text {
    color: rgb(255, 200, 67);
    font-size: 3rem;
  }
  .herbarium-advancesearch-text3 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
  }
  .herbarium-advancesearch-herbarium-list {
    align-items: flex-start;
  }
}
@media(max-width: 1000px) {
  .herbarium-advancesearch-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 76.00%,rgb(20, 67, 114) 89.00%);
  }
  .herbarium-advancesearch-herbarium-list {
    align-items: center;
    justify-content: center;
  }
}
@media(max-width: 767px) {
  .herbarium-advancesearch-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-position: bottom;
  }
  .herbarium-advancesearch-text {
    text-align: center;
  }
  .herbarium-advancesearch-text3 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .herbarium-advancesearch-herbarium-list {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .herbarium-advancesearch-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .herbarium-advancesearch-text3 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .herbarium-advancesearch-herbarium-list {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .herbarium-advancesearch-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 77.00%,rgb(20, 67, 114) 89.00%);
  }
  .herbarium-advancesearch-hero {
    height: auto;
  }
  .herbarium-advancesearch-text {
    height: 184px;
  }
  .herbarium-advancesearch-text3 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
    text-align: left;
  }
  .herbarium-advancesearch-search-tab {
    height: auto;
  }
}
