div*{
    font-family: 'prompt';

}
.text01 {
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 50px;
    color: #000000;
    margin-left: 10px;
}

/* .text02 {
    font-size: 40px;
    color: #000000;
    
} */

.icon {
    margin:auto;
    width: 122px;
    height: 122px;
}

.text1 {
    height: 50px;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 54px;
    color: #000000;
}

.line {
    border: 1px solid #000000;
}

.text2 {
    height: 33px;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
    color: #000000;

}



@media (max-width: 1268px) {
    .text2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 1178px) {
    .text2 {
        font-size: 18px;
        line-height: 30px;
    }
}

@media (max-width: 1008px) {
    .text2 {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (max-width: 928px) {
    .text2 {
        font-size: 16px;
        line-height: 30px;
    }
}
@media (max-width: 908px) {
    .text2 {
        font-size: 14px;
        line-height: 30px;
    }
}

@media (max-width: 898px) {
    .text2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 406px) {
    .text2 {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (max-width: 352px) {
    .text2 {
        font-size: 12px;
        line-height: 30px;
    }
}
@media (max-width: 352px) {
    .text1 {
        font-size: 28px;
        line-height: 30px;
    }
}

@media (max-width: 1102px) {
    .text1 {
        font-size: 28px;
        line-height: 30px;
    }
}

@media (max-width: 452px) {
    .text01 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 1102px) {
    .text01 {
        font-size: 20px;
        line-height: 30px;
    }
}







.container {
    display: flex; /* Use flexbox to display items in a row */
    align-items: center; /* Align items vertically in the center */
  }

.iconv2 {
    margin-top: 20px;
    margin-left: 30px;
    margin-right: auto;
    width: 35px;
    height: 35px;
}
.text2v2 {
    margin-top: 30px;
    position: absolute;
    margin-left: 90px;
    margin-right: auto;
    font-weight: 400;
    font-size: 16px;
    color: #ffffff;
    margin-bottom: 10px;
    font-family: 'Prompt';

}
.text01v2 {
    /* margin-top: 20px; */
    justify-content: center;
    align-items: center;
    /* margin-left: 40px; */
    margin-right: auto;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 35px;
    color: #ffffff;
   
}
.box{
    width: 120px;
    height: 32px;
    margin-bottom: -15px;
    background-color: rgb(112, 205, 187, 0.8);
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.25);
    position: relative;
    z-index: 2;
}

.box-text{
    font-weight: medium;
    font-size: 18px;
    color: rgb(0, 0, 0);
    text-align: center;
    font-family: 'Prompt';
    padding: 2px;
}

.container000 {
    display: flex; /* Use flexbox to display items in a row */
    align-items: center; /* Align items vertically in the center */
  }

@media (max-width: 1268px) {
    .text2v2 {
        font-size: 20px;
        line-height: 30px;
        
    }
}

@media (max-width: 1178px) {
    .text2v2 {
        font-size: 18px;
        line-height: 30px;
    }
}
@media (max-width: 1030px) {
    .text2v2 {
        font-size: 17px;
        line-height: 30px;
    }
}
@media (max-width: 1023px) {
    .text2v2 {
        font-size: 17px;
        line-height: 30px;
    }
}

@media (max-width: 1004px) {
    .text2v2 {
        font-size: 16px;
        line-height: 30px;
    }
}
@media (max-width: 999px) {
    .text2v2 {
        font-size: 15px;
        line-height: 30px;
    }
}

@media (max-width: 990px) {
    .text2v2 {
        font-size: 16px;
        line-height: 30px;
    }
}

@media (max-width: 963px) {
    .text2v2 {
        font-size: 14px;
        line-height: 30px;
    }
}

@media (max-width: 900px) {
    .text2v2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 719px) {
    .text2v2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 663px) {
    .text2v2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 623px) {
    .text2v2 {
        font-size: 20px;
        line-height: 30px;
    }
}

@media (max-width: 411px) {
    .text2v2 {
        font-size: 18px;
        line-height: 30px;
    }
}
@media (max-width: 388px) {
    .text2v2 {
        font-size: 14px;
        line-height: 30px;
    }
}
@media (max-width: 340px) {
    .text2v2 {
        font-size: 12px;
        line-height: 30px;
    }
}
@media (max-width: 318px) {
    .text2v2 {
        font-size: 10px;
        line-height: 30px;
    }
}



