.plant-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}
.plant-hero {
  width: 100%;
  height: 494px;
  display: flex;
  position: relative;
  min-height: auto;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("/playground_assets/rectangle512053-fvyf-800h.png");
  background-repeat: no-repeat;
  background-position: top;
}
.plant-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.plant-text {
  color: rgb(255, 200, 67);
  font-size: 3rem;
  max-width: auto;
  align-self: flex-start;
  margin-left: var(--dl-space-space-twounits);
}
.plant-text05 {
  color: rgb(255, 255, 255);
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.plant-search-tab {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.plant-textinput {
  color: #888888;
  width: 544px;
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  background-color: #e0e0e0;
}
.plant-button {
  color: #FFC843;
  margin-left: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  background-color: #144372;
}
.plant-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.plant-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.plant-herbarium-present {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.plant-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-self: center;
  align-items: flex-start;
}
.plant-navlink {
  display: contents;
}
.plant-text12 {
  color: blue;
  width: 276px;
  height: 40px;
  text-align: center;
  border-radius: var(--dl-radius-radius-radius4);
  text-transform: capitalize;
  text-decoration: underline;
}
@media(max-width: 1400px) {
  .plant-container3 {
    align-self: center;
  }
  .plant-text11 {
    align-self: center;
  }
  .plant-text12 {
    align-self: center;
  }
}
@media(max-width: 1000px) {
  .plant-herbarium-present {
    align-items: stretch;
    flex-direction: column;
    justify-content: flex-start;
  }
  .plant-container3 {
    flex-direction: column;
  }
  .plant-text12 {
    width: 369px;
  }
}
@media(max-width: 991px) {
  .plant-hero{
    width: 100%;
    height: auto;
  }
   .plant-text {
    font-size: 40px;
  }
  .plant-container1 {
    padding-top: 40px;
  }
  .plant-container2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .plant-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-position: bottom;
  }
  .plant-text {
    text-align: center;
    margin-left: auto;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
    
  }
  .plant-text01 {
    text-align: center;
  }
  .plant-text05 {
    max-width: 100%;
    margin-left: auto;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .plant-text06 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .plant-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .plant-herbarium-present {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .plant-container3 {
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .plant-text11 {
    text-align: center;
  }
  .plant-text12 {
    height: 82px;
  }
}
@media(max-width: 479px) {
  .plant-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .plant-text {
    font-size: 35px;
  }
  .plant-text05 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .plant-text06 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .plant-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .plant-container2 {
    grid-gap: var(--dl-space-space-halfunit);
  }
  .plant-herbarium-present {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .plant-hero {
    height: 733px;
  }
  .plant-text {
    /* margin-top: -20px; */
    font-size: 30px;
    align-self: flex-start;
  }
  .plant-text01 {
    height: 134px;
  }
  .plant-text05 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
    text-align: left;
  }
  .plant-text06 {
    color: #ffffff;
    font-size: 1.15rem;
  }
  .plant-textinput {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
  }
  .plant-button {
    margin-right: var(--dl-space-space-halfunit);
  }
  .plant-container3 {
    flex-direction: column;
  }
  .plant-text12 {
    width: 284px;
    height: 38px;
  }
}
