.contact-archive-container {
  width: 100%;
  display: flex;
  /* overflow: auto; */
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}

.contact-body {
  width: 100%;
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

