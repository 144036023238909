.desktop-nav-nav {
  flex: 0 0 auto;
  display: flex;
  transition: 0.3s;
  align-items: center;
  flex-direction: row;
}

.desktop-nav-nav a.active{

      color: #ffc843;

}
.desktop-nav-nav :hover{
  color: gold;
}

.desktop-nav-navlink {

  color: #ffffff;

  text-decoration: none;
}
.desktop-nav-navlink1 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.desktop-nav-navlink2 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.desktop-nav-navlink3 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.desktop-nav-navlink4 {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.desktop-nav-link {
  color: #ffffff;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
































@media(max-width: 767px) {
  .desktop-nav-nav {
    align-items: flex-start;
    flex-direction: column;
  }
  .desktop-nav-navlink {
    margin-bottom: var(--dl-space-space-unit);
  }
  .desktop-nav-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .desktop-nav-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .desktop-nav-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .desktop-nav-navlink4 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .desktop-nav-link {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 390px) {
  .desktop-nav-navlink {
    color: #000000;
  }
  .desktop-nav-navlink1 {
    color: #000000;
  }
  .desktop-nav-navlink2 {
    color: #000000;
  }
  .desktop-nav-navlink3 {
    color: #000000;
  }
  .desktop-nav-navlink4 {
    color: #000000;
  }
  .desktop-nav-link {
    color: #000000;
  }
}
