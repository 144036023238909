.homepage-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}
.homepage-hero {
  width: 100%;
  height: 494px;
  display: flex;
  position: relative;
  min-height: auto;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("/playground_assets/rectangle516237-547-800h.png");
  background-repeat: no-repeat;
  background-position: top;
}
.homepage-container01 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.homepage-text {
  color: rgb(255, 200, 67);
  font-size: 3rem;
  max-width: auto;
  align-self: flex-start;
  margin-left: var(--dl-space-space-twounits);
}
.homepage-text01 {
  color: rgb(255, 255, 255);
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.homepage-research-categories {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-container02 {
  width: 100%;
  height: 144px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: 29px;
  justify-content: center;
  background-color: #D9D9D9;
}
.homepage-text02 {
  color: #144372;
}
.homepage-text05 {
  color: rgb(61, 113, 183);
  font-size: 20px;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
}
.homepage-container03 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.homepage-blog {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: center;
}
.homepage-container04 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.homepage-navlink {
  display: contents;
}
.homepage-component01 {
  text-decoration: none;
}
.homepage-container05 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.homepage-navlink1 {
  display: contents;
}
.homepage-component02 {
  text-decoration: none;
}
.homepage-container06 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.homepage-navlink2 {
  display: contents;
}
.homepage-component03 {
  text-decoration: none;
}
.homepage-container07 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.homepage-navlink3 {
  display: contents;
}
.homepage-component04 {
  text-decoration: none;
}
.homepage-researcher-categories {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.homepage-container08 {
  width: 100%;
  height: 144px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-threeunits);
  padding-left: var(--dl-space-space-threeunits);
  border-radius: var(--dl-radius-radius-radius8);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: 29px;
  justify-content: center;
  background-color: #D9D9D9;
}
.homepage-text08 {
  color: rgb(23, 62, 127);
}
.homepage-text11 {
  color: #3d71b7;
  font-size: 20px;
  max-width: 600px;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  margin-bottom: var(--dl-space-space-twounits);
}
.homepage-researcher {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homepage-container09 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: center;
}
.homepage-container10 {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: 5px;
  flex-direction: row;
  justify-content: center;
  background-color: #ffffff;
}
.homepage-container11 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.homepage-container12 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.homepage-container13 {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-self: flex-start;
  align-items: center;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: 5px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
@media(max-width: 1000px) {
  .homepage-container {
    /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 77.00%,rgb(20, 67, 114) 89.00%); */
  }
  .homepage-container02 {
    height: 189px;
  }
  .homepage-blog {
    width: 1000px;
    height: 751px;
    flex-wrap: wrap;
    align-self: flex-start;
    align-items: stretch;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: space-between;
  }
  .homepage-container04 {
    align-self: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .homepage-container05 {
    align-self: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .homepage-container06 {
    align-self: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .homepage-container07 {
    align-self: center;
    margin-right: 0px;
    justify-content: space-between;
  }
  .homepage-container08 {
    height: 189px;
  }
  .homepage-researcher {
    height: 700px;
    align-self: center;
    align-items: stretch;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-unit);
    justify-content: space-between;
  }
  .homepage-container09 {
    height: auto;
    flex-wrap: wrap;
    align-self: stretch;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .homepage-container10 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
    background-color: transparent;
  }
  .homepage-container11 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
    background-color: transparent;
  }
  .homepage-container12 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
    background-color: transparent;
  }
  .homepage-container13 {
    width: auto;
    height: auto;
    align-self: center;
    align-items: stretch;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    flex-direction: row;
    padding-bottom: 0px;
    justify-content: space-between;
    background-color: transparent;
  }
}
@media(max-width: 991px) {
  .homepage-hero{
    width: 100%;
    height: auto;
  }
   .homepage-text {
    font-size: 40px;
  }
  .homepage-container01 {
    padding-top: 40px;
  }
  .homepage-text02 {
    text-align: center;
  }
  .homepage-text05 {
    text-align: center;
  }
  .homepage-blog {
    flex-direction: column;
  }
  .homepage-container04 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .homepage-container05 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .homepage-container07 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .homepage-text08 {
    text-align: center;
  }
  .homepage-text11 {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .homepage-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-position: bottom;
  }
  .homepage-text {
    text-align: center;
    margin-left: auto;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .homepage-text01 {
    max-width: 100%;
    text-align: center;
    margin-left: auto;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .homepage-container02 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-blog {
    width: 767px;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-container08 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-researcher {
    height: auto;
  }
  .homepage-container09 {
    width: 100%;
    flex-direction: row;
  }
  .homepage-container10 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container11 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container12 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .homepage-container13 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
   .homepage-text {
    font-size: 35px;
  }
  .homepage-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-text01 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .homepage-container02 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-blog {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container08 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container10 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container11 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container12 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .homepage-container13 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .homepage-hero {
    height: 733px;
  }
  .homepage-text {
    margin-top: -40px;
    font-size: 35px;
    align-self: flex-start;
  }
  .homepage-text01 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
  }
  .homepage-container02 {
    height: 208px;
  }
  .homepage-text02 {
    text-align: center;
  }
  .homepage-container03 {
    flex-direction: row;
  }
  .homepage-blog {
    height: 1389px;
    flex-direction: column;
  }
  .homepage-container04 {
    margin-right: 0px;
  }
  .homepage-container05 {
    margin-right: 0px;
  }
  .homepage-container06 {
    margin-right: 0px;
  }
  .homepage-container07 {
    margin-right: 0px;
  }
  .homepage-container08 {
    height: 208px;
  }
  .homepage-researcher {
    height: auto;
    align-self: center;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
  }
  .homepage-container09 {
    justify-content: center;
  }
  .homepage-container10 {
    height: auto;
    align-self: flex-start;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
  .homepage-container11 {
    height: auto;
    align-self: flex-start;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
  .homepage-container12 {
    height: auto;
    align-self: flex-start;
    padding-top: 0px;
    padding-left: 0px;
    margin-bottom: var(--dl-space-space-twounits);
    padding-right: 0px;
    justify-content: center;
  }
  .homepage-container13 {
    height: auto;
    align-self: flex-start;
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
    justify-content: center;
  }
}
