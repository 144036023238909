.researcher-name-header-container {
  display: flex;
  position: relative;
}
.researcher-name-header-text {
  font-size: 2em;
  margin-bottom: var(--dl-space-space-unit);
}

@media(max-width: 390px) {
  .researcher-name-header-container {
    flex-direction: column;
  }
}
