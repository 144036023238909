.animal-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}
.animal-hero {
  width: 100%;
  height: 494px;
  display: flex;
  position: relative;
  min-height: auto;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-color: #D9D9D9;
  background-image: url("/playground_assets/rectangle512457-mt1h-800h.png");
  background-repeat: no-repeat;
  background-position: center;
}
.animal-container1 {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.animal-text {
  color: rgb(255, 200, 67);
  font-size: 3rem;
  max-width: auto;
  align-self: flex-start;
  margin-left: var(--dl-space-space-twounits);
}
.animal-text05 {
  color: rgb(255, 255, 255);
  font-size: 1.15rem;
  max-width: 60%;
  margin-top: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-threeunits);
}
.animal-search-tab {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animal-textinput {
  color: #888888;
  width: 544px;
  border-width: 0px;
  margin-right: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-unit);
  background-color: #e0e0e0;
}
.animal-button {
  color: #FFC843;
  margin-left: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  background-color: #144372;
}
.animal-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.animal-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
@media(max-width: 991px) {
  .animal-hero{
    width: 100%;
    height: auto;
  }
   .animal-text {
    font-size: 40px;
  }
  .animal-container1 {
    padding-top: 40px;
  }
  .animal-container2 {
    grid-template-columns: 1fr 1fr;
  }
}
@media(max-width: 767px) {
  .animal-hero {
    align-items: center;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    background-position: bottom;
  }
  .animal-text {
    text-align: center;
    margin-left: auto;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .animal-text01 {
    text-align: center;
  }
  .animal-text05 {
    max-width: 100%;
    margin-left: auto;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .animal-text06 {
    max-width: 100%;
    text-align: center;
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
  .animal-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .animal-hero {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .animal-text {
    font-size: 35px;
  }
  .animal-text05 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .animal-text06 {
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .animal-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .animal-container2 {
    grid-gap: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 390px) {
  .animal-hero {
    height: 733px;
  }
  .animal-text {
    font-size: 30px;
    align-self: flex-start;
  }
  .animal-text01 {
    height: 234px;
  }
  .animal-text05 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
    text-align: left;
  }
  .animal-text06 {
    color: rgb(255, 255, 255);
    font-size: 1.15rem;
  }
  .animal-textinput {
    margin-left: var(--dl-space-space-halfunit);
    margin-right: 0px;
  }
  .animal-button {
    margin-right: var(--dl-space-space-halfunit);
  }
}
