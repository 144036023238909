.img-logo {
    width: 90px;
    height: 75px;
    color: rgba(17, 172, 142);
    /* clip-path: circle(50%); */
}

.nav-nav {
    /* font-weight: 80; */
}

.nav-nav :hover {
    color: rgba(17, 172, 142);
}

.nav-link {
    font-weight: 500;
    color: #5B5B5B;
    /* display: inline; */
    margin-right: 60px;
    text-decoration: none;
}

.nav-nav a.active {
    color: rgba(17, 172, 142);
}

.nav-mobile {
    display: none;
}




@media (max-width: 768px) {
    .nav-nav {
        display: none;
    }

    .nav-mobile {
        display: flex;
    }


}





@media (max-width: 601px) {
    .img-logo {
        margin-left: -128px;
    }
}