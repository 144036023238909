.header-header {
  width: 100%;
  display: flex;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-fourunits);
  justify-content: space-between;
  background-color: #144372;
}
.header-navlink {
  display: contents;
}
.header-image {
  /* width: 30%; */
  height: auto;
  object-fit: cover;
  text-decoration: none;
}
.header-nav {
  display: flex;
}
/* .header-nav a.active{
  color: #ffc843;
} */
.header-burger-menu {
  display: none;
}
.header-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  display: none;
}
.header-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.header-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.header-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.header-image1 {
  width: 70px;
  object-fit: cover;
}
.header-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}
.header-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

@media (max-width: 1000px) {
  .header-header {
    background-color: #144372;
  }
  .header-image {
    /* width: 30%; */
    height: auto;
  }
}
@media (max-width: 991px) {
  .header-icon {
    display: flex;
  }
}
@media (max-width: 767px) {
  .header-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .header-image {
    /* width: 30%; */
    height: auto;
  }
  .header-nav {
    display: none;
  }
  .header-burger-menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .header-header {
    padding: var(--dl-space-space-unit);
  }
  .header-mobile-menu {
    padding: 16px;
  }
}
@media (max-width: 390px) {
  .header-image {
    /* width: 30%; */
    height: auto;
  }
  .header-mobile-menu {
    display: none;
  }
  .header-nav1 {
    height: 161px;
  }
  .header-container {
    align-self: flex-start;
    justify-content: space-between;
  }
  .header-image1 {
    width: 184px;
    height: 116px;
    align-self: center;
  }
}
