.detail-fields-container {
  display: flex;
  position: relative;
  align-items: center;
  margin-bottom: var(--dl-space-space-halfunit);
  flex-direction: row;
  justify-content: center;
}
.detail-fields-text {
  font-size: 1.5em;
  align-self: center;
}
.detail-fields-textinput {
  margin-left: var(--dl-space-space-halfunit);
}






























