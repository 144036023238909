.herbarium-item-list-testimonial-card {
  width: 100%;
  height: 122px;
  display: flex;
  /* max-width: var(--dl-size-size-maxwidth); */
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  padding-top: var(--dl-space-space-unit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-unit);
  justify-content: space-between;
  margin-top: 20px;
}
.herbarium-item-list-text {
  color: #000000;
  font-size: 24px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  padding-bottom: 0px;
}
.herbarium-item-list-text1 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.herbarium-item-list-text2 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  margin-bottom: 0px;
  text-transform: capitalize;
}
.herbarium-item-list-text3 {
  color: #000000;
  font-size: 16px;
  align-self: center;
  font-style: normal;
  text-align: left;
  font-weight: 400;
  text-transform: capitalize;
}
.herbarium-item-list-navlink {
  color: var(--dl-color-gray-white);
  text-decoration: none;
  background-color: #144372;
}


@media(max-width: 1600px) {
  .herbarium-item-list-testimonial-card {
    width: 100%;
    height: 111px;
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-unit);
  }
  .herbarium-item-list-text {
    color: #000000;
    padding-bottom: 0px;
    width:25%;
  }
  .herbarium-item-list-text1 {
    color: #000000;
    margin-bottom: 0px;
    width:25%;
  }
  .herbarium-item-list-text2 {
    color: #000000;
    margin-bottom: 0px;
    width:25%;
  }
  .herbarium-item-list-text3 {
    color: #000000;
    width:25%;
  }
}
@media(max-width: 1400px) {
  .herbarium-item-list-testimonial-card {
    width: 100%;
    height: 93px;
    align-items: center;
    padding-top: var(--dl-space-space-halfunit);
    padding-left: var(--dl-space-space-halfunit);
    padding-right: var(--dl-space-space-halfunit);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-halfunit);
    justify-content: space-between;
  }
  .herbarium-item-list-text {
    color: var(--dl-color-gray-black);
    padding-bottom: 0px;
  }
  .herbarium-item-list-text1 {
    color: var(--dl-color-gray-black);
    align-self: center;
    margin-bottom: 0px;
  }
  .herbarium-item-list-text2 {
    color: var(--dl-color-gray-black);
    align-self: center;
    margin-bottom: 0px;
  }
  .herbarium-item-list-text3 {
    color: var(--dl-color-gray-black);
    font-style: normal;
    font-weight: 400;
  }
  .herbarium-item-list-navlink {
    color: #FFC843;
    box-shadow: 5px 5px 10px 0px #d4d4d4;
    border-width: 0px;
    background-color: #144372;
  }
}
@media(max-width: 1000px) {
  .herbarium-item-list-testimonial-card {
    width: 100%;
    height: auto;
    flex-direction: column;
  }
}
@media(max-width: 991px) {
  .herbarium-item-list-text1 {
    align-self: center;
  }
  .herbarium-item-list-text2 {
    align-self: center;
  }
  .herbarium-item-list-text3 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .herbarium-item-list-testimonial-card {
    width: 100%;
    height: auto;
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
    flex-direction: column;
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .herbarium-item-list-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .herbarium-item-list-text {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .herbarium-item-list-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .herbarium-item-list-text2 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .herbarium-item-list-text3 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
@media(max-width: 390px) {
  .herbarium-item-list-testimonial-card {
    height: 230px;
    flex-direction: column;
    justify-content: space-between;
  }
  .herbarium-item-list-text {
    align-self: center;
    text-align: center;
  }
  .herbarium-item-list-text3 {
    text-align: center;
  }
}
