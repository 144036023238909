.advanced-search-container {
    width: 100%;
    display: flex;
    /* overflow: auto; */
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
  }

.advanced-search-archive-card-container {
    flex: 0 0 auto;
    width: 100%;
    display: flex;
    grid-gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
    /* border-radius: 8px; */
    padding: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
}

@media(max-width: 991px) {

    .advanced-search-archive-card-container {
        grid-template-columns: 1fr 1fr;
    }
}

@media(max-width: 479px) {

    .advanced-search-archive-card-container {
        grid-gap: var(--dl-space-space-halfunit);
    }

}