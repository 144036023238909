.researcher-card-testimonial-card {
  width: 260px;
  height: 304px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  /* margin-left: 10px; */
  /* margin-bottom: 10px; */
  align-self: center;
  margin: auto;
  padding: auto;
}
.researcher-card-container {
  width: 260px;
  height: 313px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
}
.researcher-card-container1 {
  left: 0px;
  width: 260px;
  height: 100px;
  display: flex;
  position: absolute;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-end;
  background-color: #144372CC;
}
.researcher-card-text {
  color: #ffffff;
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-weight: 700;
  padding-left: 16px;
  padding-bottom: auto;
}
.researcher-card-text1 {
  color: #fffdfd;
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-weight: 500;
  margin-left: 16px;
  margin-bottom: 8px;
  text-transform: capitalize;
  overflow-y: auto;
}
.researcher-card-text2 {
  color: #fffdfd;
  font-size: 12px;
  align-self: flex-start;
  font-style: normal;
  text-align: left;
  font-weight: 300;
  margin-left: 16px;
  margin-bottom: var(--dl-space-space-unit);
  /* text-transform: capitalize; */
}
























/* @media(max-width: 991px) {
  .researcher-card-text1 {
    align-self: center;
  }
  .researcher-card-text2 {
    align-self: center;
  }
} */
@media(max-width: 767px) {
  .researcher-card-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

/* @media(max-width: 479px) {
  .researcher-card-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .researcher-card-text {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .researcher-card-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .researcher-card-text2 {
    margin-bottom: var(--dl-space-space-unit);
  }
} */