@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

.home-page-herbarium-container {
    width: 100%;
    display: flex;
    overflow: hidden;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
    font-family: 'Prompt', sans-serif;
}

.home-page-herbarium-container-1 {
    position: relative;
    background-color: rgba(22, 149, 124, 0.7);
    height: 505px;
    padding: 80px;
    width: 100%;

}

.img-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.hero-text-container {
    padding-top: 0;
}

.hero-text-1 {
    font-weight: medium;
    font-size: 24px;
    color: rgba(255, 255, 255, 0.5);
}

.hero-text-2 {
    font-weight: bold;
    font-size: 48px;
    color: rgba(255, 255, 255);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-top: 30px;
    margin-bottom: 30px;
}

.hero-line {
    width: 709px;
    height: 2px;
    background-color: rgba(255, 255, 255);
}

.hero-icon {
    /* position: absolute; */
    display: flex;
    justify-content: flex-end;
    z-index: 2;
    width: 300px;
    height: 210px;
}

.box-on-hero {
    position: relative;
    width: 1196px;
    height: 180px;
    background-color: rgba(255, 255, 255);
    box-shadow: 2px 4px 4px rgba(17, 172, 142, 0.3);
    border-radius: 10px;
    top: -80px;
    padding: 30px 40px 20px 40px;
    z-index: 3;
}


.box-on-hero-text1 {
    font-weight: medium;
    font-size: 24px;
    color: rgba(17, 172, 142);
}

.box-on-hero-text2 {
    font-weight: light;
    font-size: 20px;
    text-align: justify;
}

.line-bottom-hero {
    width: 709px;
    height: 2px;
    background-color: rgba(17, 172, 142);
}

.content-container {
    max-width: 1536px;
    padding-top: 60px;
    padding-left: 160px;
    padding-right: 160px;
    padding-bottom: 100px;
    height: auto;
}

.content-container10 {
    max-width: 1536px;
    padding-top: 80px;
    padding-left: 160px;
    padding-right: 160px;
    padding-bottom: 80px;
    height: auto;
}

.img-herbarium-container {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img-herbarium-container-body {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.img-herbarium1 {
    max-width: 100%;
    width: 340px;
    height: auto;
    border-radius: 50%;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
}


.img-herbarium2 {
    position: absolute;
    z-index: 1;
    max-width: 100%;
    width: 223px;
    height: 223px;
    border-radius: 50%;
    top: -13%;
    left: -40%;
    /* transform: translate(-40%, -40%); */
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
}

.text-herbarium-container {
    padding-top: 50px;
    transform: translateY(20px);
    opacity: 0;
    /* margin-top: 10px; */
}

.text-content-header {
    margin-bottom: 10px;

}

.text-content-header1 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
}

.text-content-header2 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
    color: rgba(17, 172, 142);
}

.text-content-body {
    text-align: justify;
    font-size: 20px;
    font-weight: regular;
}

.content-container1 {
    background-color: rgba(213, 213, 213, 0.5);
    max-width: 1536px;
    padding-top: 60px;
    padding-left: 160px;
    padding-right: 160px;
    padding-bottom: 100px;
    height: auto;

}

.text-herbarium-container1 {
    padding-top: 50px;
    transform: translateY(20px);
    opacity: 0;
}

.text-content-header1 {
    margin-bottom: 20px;

}

.text-content-header10 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
    margin-top: 10px;
}

.text-content-header20 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
    color: rgba(17, 172, 142);
}

.text-content-body1 {
    text-align: justify;
    font-size: 20px;
    font-weight: regular;
}

.img-herbarium-container1 {
    padding: 50px;
}

.img-herbarium10 {
    /* position: absolute; */
    width: 340px;
    height: 340px;
    border-radius: 50%;
    /* clip-path: circle(50% at center); */
    margin-top: 80px;
    margin-left: 180px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    z-index: -1;

}

.img-herbarium20 {
    position: absolute;
    z-index: 1;
    max-width: 100%;
    width: 223px;
    height: 223px;
    border-radius: 50%;
    top: -8%;
    left: 70%;
    /* transform: translate(-40%, -40%); */
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);

}


.content-container3 {

    display: grid;
    row-gap: 30px;
    background-color: rgba(17, 172, 142);
    /* height: 377px; */
    justify-content: center;
    align-content: center;
    max-width: 1536px;
    padding-top: 60px;
    padding-left: 160px;
    padding-right: 160px;
    padding-bottom: 60px;
    height: auto;
}

.content-container4 {
    display: flex;
    justify-content: center;
    align-content: center;
    /* padding-top: 70px; */
}

.content-container5 {
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.text-content-body2 {
    font-size: 20px;
    font-weight: medium;
    color: rgba(255, 255, 255);
}

.content-container6 {
    background-color: rgba(213, 213, 213, 0.5);
    /* height: 920px;
    padding-top: 90px;
    margin-left: 30px; */
    max-width: 1536px;
    padding-top: 100px;
    padding-left: 160px;
    padding-right: 160px;
    padding-bottom: 100px;
    height: auto;
}

.content-container7 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 50px;
}


.content-container-body01 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.content-container-body02 {
    display: flex;
    justify-content: center;
    /* align-items: center; */
}

.content-container-body03 {
    display: flex;
    /* margin-top: -20px; */
    /* justify-content: center; */
    /* align-items: center; */
    /* padding-left: 50px; */
}

.img-herbarium31 {
    display: static;
    width: 100%;
    height: 451px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    /* justify-content: flex-end; */
    /* align-self: center; */
    margin-bottom: 50px;
    margin-top: 100px;


}

.img-herbarium32 {
    display: flex;
    width: 100%;
    height: 713px;
    box-shadow: 2px 4px 4px rgba(0, 0, 0, 0.5);
    justify-self: flex-end;



}

.text-content-header31 {
    margin-bottom: 20px;

}

.text-content-header310 {
    font-size: 40px;
    font-weight: medium;
}

.text-content-header320 {
    font-size: 40px;
    font-weight: medium;
    color: rgba(17, 172, 142);
}

.text-content-body31 {
    text-align: left;
    font-size: 20px;
    font-weight: regular;
    padding-right: 50px;
}

.text-content-header312 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
}

.text-content-header322 {
    display: inline;
    font-size: 24px;
    font-weight: medium;
    color: rgba(17, 172, 142);
}

.text-content-body32 {
    text-align: left;
    font-size: 20px;
    font-weight: regular;
    /* padding-right: 50px; */
}

.content-container-body04 {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-bottom: 80px; */

}

.text-content-body33 {
    width: 100%;
    text-align: justify;
    font-size: 20px;
    font-weight: regular;
    /* padding-right: 50px; */
}


.text-content-header32 {
    display: flex;
    /* margin-top: 60px; */
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.text-content-header313 {
    display: flex;
    font-size: 24px;
    font-weight: medium;
    /* text-align: center; */
}

.text-content-header323 {
    display: flex;
    font-size: 24px;
    font-weight: medium;
    /* text-align: center; */
    color: rgba(17, 172, 142);
}

.text-content-body34 {
    text-align: center;
    font-size: 20px;
    font-weight: regular;
    /* padding-right: 50px; */
}

.text-slide {
    opacity: 0;
    transform: translateY(20px);
    margin-top: 20px;
}

.fade-in {
    opacity: 1;
    transform: translateY(-20px);
    transition: opacity 1.3s ease-in-out, transform 1.3s ease-in-out;
}

@media (max-width: 1440px) {
    .content-container7 {

        grid-template-columns: repeat(2, 1fr);
        column-gap: 50px;
    }

    .content-container-body02 {
        display: none;
    }

    .img-herbarium31 {
        margin-bottom: 0px;
        margin-top: 0px;
        height: 300px;
    }

    .text-content-header310, .text-content-header320 {
        font-size: 32px;

    }
}

@media (max-width: 1345px) {
    .box-on-hero {
        width: auto;
        height: inherit;
        margin-left: 100px;
        margin-right: 100px;
    }

    .hero-line {
        width: inherit;

    }

    .img-herbarium31 {
        height: 60%;

    }

}

@media (max-width: 1200px) {
    .img-herbarium-container-body {
        position: static;
        display: grid;
        row-gap: 20px;
        /* grid-template-columns: 1; */
    }

    .img-herbarium2, .img-herbarium1, .img-herbarium20 {
        position: static;
        width: 200px;
        height: 200px;
    }

    .img-herbarium31 {
        height: 70%;

    }
}

@media (max-width: 1129px) {
    .hero-icon {
        transform: scale(0.9);
        justify-content: flex-end;

    }

    .img-herbarium31 {
        height: 75%;

    }
}

@media (max-width: 1004px) {
    .hero-icon {
        transform: scale(0.7);
        justify-content: flex-end;

    }

    .hero-text-1 {
        font-size: 20px;
    }

    .hero-text-2 {
        font-size: 40px;
    }


}

@media (max-width: 900px) {
    .content-container-body03 {
        margin-top: 60px;
    }

    .content-container-body01 {
        height: 80%;
    }

    .text-content-header31 {
        display: flex;
        justify-content: center;
    }

    .text-content-header310, .text-content-header320 {
        text-align: center;
        font-size: 24px;
    }

    .img-herbarium31 {
        height: 250px;

    }

    .content-container7 {
        display: flex;
        flex-direction: column;
        /* grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(2, 1fr);
        column-gap: 50px; */
    }

    .img-herbarium2, .img-herbarium20 {
        display: none;
    }

    .img-herbarium-container {
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -50px;
        margin-bottom: -20px;

    }

    .img-herbarium1 {
        width: 350px;
        height: 350px;
    }

    .img-herbarium-container-body {
        height: auto;
    }

    .hero-icon {
        display: none;
        justify-content: flex-end;

    }

    .hero-text-1 {
        text-align: center;
        word-wrap: break-word;
    }

    .hero-text-2 {
        text-align: center;
        word-wrap: break-word;
    }

    .box-on-hero-text1 {
        text-align: center;
    }

    .box-on-hero-text2 {
        text-align: center;
    }
}

/* @media (max-width: 875px) {
    .content-container7 {
        display: grid;
        grid-template-rows: 0.8fr 0.8fr;
        column-gap: 50px;
    }

    .img-herbarium31 {
        height: 200px;
    }


} */

@media (max-width: 807px) {
    .line-bottom-hero {
        width: 80%;
        /* margin-top: 250px; */
    }

    .content-container-body03 {
        margin-top: 60px;
    }

}

@media (max-width: 768px) {

    .content-container, .content-container1, .content-container3, .content-container6, .content-container10 {
        padding-top: 60px;
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 100px;
    }

    .hero-text-1 {
        word-wrap: break-all;
    }

    .hero-text-2 {
        word-wrap: break-all;
    }

    .box-on-hero-text1 {
        font-size: 20px;
    }

    .box-on-hero-text2 {
        font-size: 18px;
    }

    .text-content-header1 {
        font-size: 20px;
    }

    .text-content-header2 {
        font-size: 20px;
    }

    .text-content-body {
        font-size: 18px;
    }
}

@media (max-width: 630px) {
    .line-bottom-hero {
        /* margin-top: 300px; */
    }

}

@media (max-width: 533px) {
    .hero-text-1 {
        font-size: 20px;
    }

    .hero-text-2 {
        font-size: 30px;
    }

    .box-on-hero {
        width: 90%;
    }

    .text-content-header32 {
        flex-direction: column;
    }
}

@media (max-width: 467px) {
    .img-herbarium1 {
        width: auto;
        height: auto;
    }

    .img-herbarium-container {
        /* transform: scale(0.7); */
        padding: 0;
        height: auto;
    }

    .box-on-hero-text1 {
        font-size: 18px;

    }

    .box-on-hero-text2 {
        font-size: 16px;
        margin-top: 10px;
    }

    .text-content-header1, .text-content-header2, .text-content-header310, .text-content-header320, .text-content-header313, .text-content-header323 {
        font-size: 18px;
    }



    .text-content-body, .text-content-body2, .text-content-body33, .text-content-body34 {
        font-size: 16px;
    }
}