.footer-content-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-content-container1 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.footer-content-text {
  color: #144372;
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 700;
}

.footer-content-text1 {
  color: #3D71B7;
}
.footer-content-text2 {
  color: #3D71B7;
}
.footer-content-text3 {
  color: #3D71B7;
}

.footer-herbarium-content-text {
  color: #144372;
  font-size: 24px;
  font-style: normal;
  margin-top: var(--dl-space-space-oneandhalfunits);
  font-weight: 700;
}

.footer-content-links-container {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.footer-content-image {
  height: auto;
}

@media(max-width: 979px) {
  .footer-content-links-container {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }

  .footer-content-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media(max-width: 767px) {
  .footer-content-container {
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
  }
  .footer-content-text {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-content-links-container {
    width: 100%;
    margin-top: var(--dl-space-space-halfunit);
    align-items: flex-start;
    flex-direction: row;
    justify-content: center;
  }
  .footer-content-image {
    margin-bottom: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .footer-content-container {
    align-items: center;
    flex-direction: column;
  }
  .footer-content-text {
    margin-top: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .footer-content-links-container {
    align-items: center;
    flex-direction: column;
  }
  .footer-content-image {
    margin-bottom: 0px;
  }
}
@media(max-width: 390px) {
  .footer-content-links-container {
    align-self: center;
    margin-top: var(--dl-space-space-unit);
    align-items: center;
    justify-content: center;
  }
}
