.herbarium-detail-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 89.00%,rgb(20, 67, 114) 89.00%); */
}
.herbarium-detail-research-content {

  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  align-items: center;
}
.herbarium-detail-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.herbarium-detail-container1 {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}
.herbarium-detail-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: space-between;
  flex-direction: column;
}
.herbarium-detail-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: space-between;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.herbarium-detail-image {
  flex: 1;
  width: 260px;
  height: 336px;
  align-self: center;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  object-position: left;
}
.herbarium-detail-navlink {
  color: var(--dl-color-gray-white);
  width: 200px;
  height: auto;
  align-self: center;
  text-align: center;
  text-decoration: none;
  background-color: rgb(20, 67, 114);
}
.herbarium-detail-container4 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media(max-width: 991px) {
  .herbarium-detail-research-content {
    margin-top: 10%;
    align-items: center;
  }
  .herbarium-detail-container1 {
    flex-direction: column;
  }
  .herbarium-detail-image {
    width: 300px;
    height: 300px;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }
}
@media(max-width: 767px) {
  .herbarium-detail-research-content {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .herbarium-detail-container1 {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .herbarium-detail-research-content {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .herbarium-detail-image {
    width: 250px;
    height: 250px;
  }
  .herbarium-detail-container4 {
    grid-template-columns: 1fr;
  }
}
@media(max-width: 390px) {
  .herbarium-detail-research-content {
    height: 1967px;
    margin-top: 204px;
  }
}
