.borrowform-container {
  width: 587px;
  height: 400px;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.borrowform-container01 {
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.borrowform-container02 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.borrowform-text {
  font-size: 1.5em;
}
.borrowform-container03 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.borrowform-container04 {
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.borrowform-container05 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.borrowform-text1 {
  font-size: 1.5em;
}
.borrowform-container06 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.borrowform-container07 {
  flex: 0 0 auto;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.borrowform-container08 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-unit);
  flex-direction: column;
  justify-content: center;
}
.borrowform-text2 {
  font-size: 1.5em;
}
.borrowform-container09 {
  flex: 0 0 auto;
  width: 200px;
  height: 100px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.borrowform-button {
  color: var(--dl-color-gray-white);
  width: 200px;
  height: auto;
  align-self: center;
  text-align: center;
  background-color: rgb(20, 67, 114);
}
.borrowform-root-class-name {
  align-self: center;
}
@media(max-width: 390px) {
  .borrowform-container {
    width: 100%;
    height: auto;
  }
  .borrowform-container01 {
    width: 100%;
    flex-direction: column;
  }
  .borrowform-container04 {
    width: 100%;
    flex-direction: column;
  }
  .borrowform-container07 {
    width: 100%;
    flex-direction: column;
  }
  .borrowform-button {
    margin-top: var(--dl-space-space-twounits);
  }
}
