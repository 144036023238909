.borrowview-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 85.00%,rgb(20, 67, 114) 85.00%); */
}
.borrowview-pricing {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
  
}
.borrowview-header-list {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
}
.borrowview-container01 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.borrowview-container02 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container03 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container04 {
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container05 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-borrow {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}
.borrowview-container06 {
  flex: 0 0 auto;
  width: 25%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.borrowview-container07 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container08 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container09 {
  flex: 0 0 auto;
  width: 30%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container10 {
  flex: 0 0 auto;
  width: 15%;
  height: auto;
  display: flex;
  align-items: center;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.borrowview-borrow1 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}
.borrowview-container11 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.borrowview-container12 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container13 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container14 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container15 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
.borrowview-borrow2 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-halfunit);
  align-items: center;
  justify-content: space-between;
}
.borrowview-container16 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.borrowview-container17 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container18 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container19 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  flex-direction: column;
}
.borrowview-container20 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-unit);
  margin-right: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
}
@media(max-width: 767px) {
  .borrowview-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 94.00%,rgb(20, 67, 114) 91.00%);
  }
  .borrowview-pricing {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .borrowview-pricing {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .borrowview-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 98.00%,rgb(20, 67, 114) 94.00%);
  }
}

.scrollable-cell {
  max-height: 100px; /* Update the max-height value to a smaller value, such as 30px */
  overflow-x: auto;
}
