.adminmenu-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 85.00%,rgb(20, 67, 114) 85.00%); */
}
.adminmenu-manage-section {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.adminmenu-text {
  align-self: center;
}
.adminmenu-container1 {
  width: 100%;
  display: flex;
  align-self: stretch;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.adminmenu-navlink {
  display: contents;
}
.adminmenu-testimonial-card {
  width: 266px;
  height: 291px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  text-decoration: none;
  background-image: url("/playground_assets/rectangle516237-547-300h.png");
  background-position: center;
}
.adminmenu-testimonial {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.46);
}
.adminmenu-text3 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-halfunit);
}
.adminmenu-navlink1 {
  display: contents;
}
.adminmenu-testimonial-card1 {
  width: 266px;
  height: 291px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  text-decoration: none;
  background-image: url("/playground_assets/rectangle516237-547-300h.png");
  background-position: center;
}
.adminmenu-testimonial1 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.46);
}
.adminmenu-text4 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-halfunit);
}
.adminmenu-navlink2 {
  display: contents;
}
.adminmenu-testimonial-card2 {
  width: 266px;
  height: 291px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  text-decoration: none;
  background-image: url("/playground_assets/rectangle516237-547-300h.png");
  background-position: center;
}
.adminmenu-testimonial2 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  background-color: rgba(0, 0, 0, 0.46);
}
.adminmenu-text5 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-halfunit);
}
.adminmenu-navlink3 {
  display: contents;
}
.adminmenu-testimonial-card3 {
  width: 266px;
  height: 291px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  text-decoration: none;
  background-image: url("/playground_assets/rectangle516237-547-300h.png");
  background-position: center;
}
.adminmenu-testimonial3 {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.adminmenu-text6 {
  color: rgb(255, 255, 255);
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-halfunit);
}
@media(max-width: 1000px) {
  .adminmenu-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 94.00%);
  }
  .adminmenu-manage-section {
    height: auto;
    align-self: center;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;
  }
  .adminmenu-container1 {
    flex-direction: column;
    justify-content: center;
  }
  .adminmenu-testimonial-card {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .adminmenu-testimonial {
    width: 268px;
    justify-content: center;
  }
  .adminmenu-text3 {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
  }
  .adminmenu-testimonial-card1 {
    margin-top: var(--dl-space-space-halfunit);
    background-color: rgba(0, 0, 0, 0.25);
  }
  .adminmenu-testimonial1 {
    width: 268px;
    justify-content: center;
  }
  .adminmenu-text4 {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
  }
  .adminmenu-testimonial-card2 {
    margin-top: var(--dl-space-space-halfunit);
    background-color: rgba(0, 0, 0, 0.25);
  }
  .adminmenu-testimonial2 {
    width: 268px;
    justify-content: center;
  }
  .adminmenu-text5 {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
  }
  .adminmenu-testimonial-card3 {
    margin-top: var(--dl-space-space-halfunit);
    background-color: rgba(0, 0, 0, 0.25);
  }
  .adminmenu-testimonial3 {
    width: 268px;
    justify-content: center;
  }
  .adminmenu-text6 {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
  }
}
@media(max-width: 991px) {
  .adminmenu-manage-section {
    align-items: center;
  }
}
@media(max-width: 767px) {
  .adminmenu-manage-section {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .adminmenu-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .adminmenu-testimonial-card1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .adminmenu-testimonial-card2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .adminmenu-testimonial-card3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .adminmenu-manage-section {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .adminmenu-testimonial-card {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .adminmenu-text3 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .adminmenu-testimonial-card1 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .adminmenu-text4 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .adminmenu-testimonial-card2 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .adminmenu-text5 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .adminmenu-testimonial-card3 {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .adminmenu-text6 {
    margin-bottom: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 390px) {
  .adminmenu-manage-section {
    margin-top: var(--dl-space-space-fiveunits);
  }
}
