@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

.staff-details-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 85.00%,rgb(20, 67, 114) 85.00%); */
}

.staff-details-reseacrher-content {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
  align-items: center;
}

.staff-details-separator {
  width: 400px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}

.staff-details-container1 {
  flex: 0 0 auto;
  width: fit-content;
  display: flex;
  box-shadow: 5px 5px 10px 0px #00000056;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: center;
}

.staff-details-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: space-between;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}

.staff-details-image {
  flex: 1;
  width: 260px;
  height: 336px;
  align-self: center;
  object-fit: cover;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  object-position: left;
}

.staff-details-link {
  color: var(--dl-color-gray-white);
  width: 200px;
  height: auto;
  align-self: center;
  text-align: center;
  text-decoration: none;
  background-color: rgb(20, 67, 114);
}

.staff-details-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.herbarium-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  font-family: 'Prompt', sans-serif;
  margin: auto;

  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 67.00%,rgb(20, 67, 114) 89.00%); */
}

.herbarium-header-text {
  font-weight: bold;
  font-size: 48px;
  color: #10ac8db7;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.39);
  margin-top: 20px;
}

.line-hero1 {
  width: 820px;
  height: 3px;
  background-color: rgb(255, 255, 255);
}

.staff-text-1 {
  font-weight: bold;
  font-size: 48px;
  color: rgba(255, 255, 255);
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: 30px;
  margin-bottom: 30px;
}

.staff-text-2 {
  font-weight: medium;
  font-size: 14px;
  color: rgba(255, 255, 255);
  /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
  margin-top: 30px;
  margin-bottom: 30px;
}

.staff-page-herbarium-container-1 {
  position: relative;
  background-color: rgba(22, 149, 124, 0.7);
  height: 505px;
  padding: 80px;
  width: 100%;
  font-family: 'Prompt', sans-serif;

}

.staff-img-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

@media (max-width: 768px) {
  .herbarium-header-text {
    font-size: 32px;
    text-align: center;
  }

  .staff-page-herbarium-container-1 {
    padding: 40px;
  }
}

@media (max-width: 1345px) {
  .line-hero1 {
    width: inherit;
  }
}

@media(max-width: 767px) {
  .staff-details-reseacrher-content {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .staff-details-container1 {
    flex-direction: column;
  }
}


@media (max-width: 480px) {
  .staff-text-1 {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;

  }

  .staff-page-herbarium-container-1 {
    height: auto;
    padding: 20px;

  }
}

@media(max-width: 479px) {
  .staff-details-separator {
    width: 250px;
  }

  .staff-details-container1 {
    width: 100%;
  }

  .staff-details-reseacrher-content {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }

  .staff-details-image {
    width: 250px;
    height: 250px;
  }

  .staff-details-container3 {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 390px) {
  .researcher-details-image {
    width: 230px;
    height: 230px;
  }
}