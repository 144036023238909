.status-item-container {
  display: flex;
  position: relative;
}
/* .status-item-status-item {
  color: var(--dl-color-success-300);
} */
.status-item-root-class-name {
  align-self: center;
}
.status-item-root-class-name1 {
  align-self: center;
}
