@import url('https://fonts.googleapis.com/css2?family=Prompt&display=swap');

.font-change-container {
  font-family: 'Prompt', sans-serif;
  font-size: 16px;
  font-weight: bold;
}

.herbarium-card-testimonial-card {
  width: 260px;
  height: 304px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  /* margin-left: 10px; */
  /* margin-bottom: 10px; */
  align-self: center;
  margin: auto;
  padding: auto;
}
.herbarium-card-container {
  width: 260px;
  height: 313px;
  display: flex;
  position: relative;
  align-self: center;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  background-size: cover;
  justify-content: flex-end;
  background-repeat: no-repeat;
  background-position: center;
}
.herbarium-card-container1 {
  left: 0px;
  width: 260px;
  height: 90px;
  display: flex;
  position: absolute;
  align-self: flex-start;
  align-items: flex-start;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: column;
  justify-content: flex-end;
  background-color: #10ac8db7;
}
.herbarium-card-text {
  color: #ffffff;
  font-size: 12px;
  align-self: flex-start;
  text-align: left;
  font-weight: medium;
  padding-left: 10px;
  padding-bottom: auto;
}
.herbarium-card-text1 {
  color: #ffffff;
  font-size: 16px;
  align-self: flex-start;
  text-align: left;
  font-weight: medium;
  padding-left: 10px;
  padding-bottom: auto;
}
.herbarium-card-text2 {
  color: #fffdfd;
  font-size: 12px;
  align-self: flex-start;
  text-align: left;
  font-weight: 300;
  margin-left: 10px;
  margin-bottom: var(--dl-space-space-unit);
  /* text-transform: capitalize; */
  overflow-y: auto;
}

.herbarium-features {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}

.herbarium-container2 {
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-twounits);
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, 0.3fr);
  flex-wrap: wrap;
}












@media(max-width: 991px) {
  .herbarium-container2 {
    grid-template-columns: 1fr;
  }
}

@media(max-width: 479px) {
  .herbariumt-features {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .herbarium-container2 {
    grid-gap: var(--dl-space-space-halfunit);
  }
}

@media(max-width: 767px) {
  .herbarium-card-testimonial-card {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

  .herbarium-features {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }

}
