.details-plant-card-feature-card {
    width: 100%;
    display: flex;
    padding: var(--dl-space-space-unit);
    max-width: var(--dl-size-size-maxwidth);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .details-plant-card-text {
    font-weight: 600;
    margin-bottom: var(--dl-space-space-unit);
  }
  .details-plant-card-text1 {
    color: var(--dl-color-gray-700);
  }
  .details-plant-card-text2 {
    color: var(--dl-color-gray-700);
    font-style: italic;
  }
  .details-plant-card-root-class-name {
    width: 100%;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  @media(max-width: 479px) {
    .details-card-text {
      font-style: normal;
      font-weight: 600;
    }
  }
  