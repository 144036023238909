.archiveview-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 85.00%,rgb(20, 67, 114) 85.00%); */
}
.archiveview-archive-list {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  flex-direction: column;
}
.archiveview-navlink {
  color: var(--dl-color-gray-white);
  align-self: flex-end;
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  padding-top: var(--dl-space-space-unit);
  border-color: var(--dl-color-primary-500);
  border-width: 0px;
  padding-left: var(--dl-space-space-twounits);
  padding-right: var(--dl-space-space-twounits);
  padding-bottom: var(--dl-space-space-unit);
  text-decoration: none;
  background-color: var(--dl-color-primary-500);
}
.archiveview-navlink:hover {
  transform: scale(1.02);
}
.archiveview-container01 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: center;
}
.archiveview-lecturer-list {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.archiveview-container02 {
  display: flex;
  height: auto;
  min-width: 150px;
  width: 100%;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.archiveview-image {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.archiveview-container03 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  min-width: 200px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container04 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container05 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  min-width: 150px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container06 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container07 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container08 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
}
.archiveview-button {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-500);
}
.archiveview-button:hover {
  transform: scale(1.02);
}
.archiveview-button1 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-danger-500);
}
.archiveview-button1:hover {
  transform: scale(1.02);
}
.archiveview-lecturer-list1 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.archiveview-container09 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}
.archiveview-image1 {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.archiveview-container10 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container11 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container12 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container13 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container14 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container15 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
}
.archiveview-button2 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-500);
}
.archiveview-button2:hover {
  transform: scale(1.02);
}
.archiveview-button3 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-danger-500);
}
.archiveview-button3:hover {
  transform: scale(1.02);
}
.archiveview-lecturer-list2 {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-halfunit);
  justify-content: space-between;
  background-color: var(--dl-color-gray-white);
}
.archiveview-container16 {
  display: flex;
  min-width: 150px;
  align-items: center;
  flex-direction: column;
}
.archiveview-image2 {
  width: var(--dl-size-size-small);
  object-fit: cover;
}
.archiveview-container17 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container18 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container19 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container20 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container21 {
  flex: 0 0 auto;
  width: var(--dl-size-size-medium);
  height: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.archiveview-container22 {
  flex: 0 0 auto;
  width: auto;
  height: 100%;
  display: flex;
  align-self: center;
  flex-direction: row;
  justify-content: flex-end;
}
.archiveview-button4 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-gray-500);
}
.archiveview-button4:hover {
  transform: scale(1.02);
}
.archiveview-button5 {
  color: var(--dl-color-gray-white);
  transition: 0.3s;
  flex-shrink: 0;
  margin-left: var(--dl-space-space-halfunit);
  background-color: var(--dl-color-danger-500);
}
.archiveview-button5:hover {
  transform: scale(1.02);
}
@media(max-width: 991px) {
  .archiveview-navlink {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-text03 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text04 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text05 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text06 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text07 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-button {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-button1 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-text08 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text09 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text10 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text11 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text12 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-button2 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-button3 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-text13 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text14 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text15 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text16 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-text17 {
    margin-left: var(--dl-space-space-threeunits);
    margin-right: var(--dl-space-space-threeunits);
  }
  .archiveview-button4 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-button5 {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 767px) {
  .archiveview-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 94.00%,rgb(20, 67, 114) 91.00%);
  }
  .archiveview-archive-list {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .archiveview-lecturer-list {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .archiveview-container03 {
    justify-content: center;
  }
  .archiveview-text03 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container04 {
    justify-content: center;
  }
  .archiveview-text04 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container05 {
    flex-direction: row;
    justify-content: center;
  }
  .archiveview-text05 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container06 {
    justify-content: center;
  }
  .archiveview-text06 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container07 {
    justify-content: center;
  }
  .archiveview-text07 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-button {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .archiveview-lecturer-list1 {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .archiveview-container10 {
    justify-content: center;
  }
  .archiveview-text08 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container11 {
    justify-content: center;
  }
  .archiveview-text09 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container12 {
    flex-direction: row;
    justify-content: center;
  }
  .archiveview-text10 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container13 {
    justify-content: center;
  }
  .archiveview-text11 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container14 {
    justify-content: center;
  }
  .archiveview-text12 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-button2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
  .archiveview-lecturer-list2 {
    width: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }
  .archiveview-container17 {
    justify-content: center;
  }
  .archiveview-text13 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container18 {
    justify-content: center;
  }
  .archiveview-text14 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container19 {
    flex-direction: row;
    justify-content: center;
  }
  .archiveview-text15 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container20 {
    justify-content: center;
  }
  .archiveview-text16 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-container21 {
    justify-content: center;
  }
  .archiveview-text17 {
    margin-top: var(--dl-space-space-twounits);
    text-align: center;
    margin-left: var(--dl-space-space-twounits);
    margin-right: var(--dl-space-space-twounits);
    margin-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-button4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button5 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: var(--dl-space-space-halfunit);
  }
}
@media(max-width: 479px) {
  .archiveview-archive-list {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .archiveview-text03 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text04 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text05 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text06 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text07 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text08 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text09 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text10 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text11 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text12 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text13 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text14 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text15 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text16 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
  .archiveview-text17 {
    margin-left: var(--dl-space-space-unit);
    margin-right: var(--dl-space-space-unit);
  }
}
@media(max-width: 390px) {
  .archiveview-container {
    background-image: linear-gradient(0deg, rgb(255, 255, 255) 98.00%,rgb(20, 67, 114) 94.00%);
  }
  .archiveview-lecturer-list {
    align-self: center;
    justify-content: center;
  }
  .archiveview-container07 {
    justify-content: center;
  }
  .archiveview-button {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button1 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-lecturer-list1 {
    align-self: center;
    justify-content: center;
  }
  .archiveview-container14 {
    justify-content: center;
  }
  .archiveview-button2 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button3 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-lecturer-list2 {
    align-self: center;
    justify-content: center;
  }
  .archiveview-container21 {
    justify-content: center;
  }
  .archiveview-button4 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
  .archiveview-button5 {
    margin-top: var(--dl-space-space-halfunit);
    margin-left: 0px;
  }
}
