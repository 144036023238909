.scientific-nameheader-container {
  display: flex;
  position: relative;
  word-wrap: break-word;
}
.scientific-nameheader-heading {
  color: rgb(20, 67, 114);
  font-size: 2rem;
  margin-bottom: var(--dl-space-space-twounits);
  
}
.scientific-nameheader-text2 {
  font-size: 2rem;
  font-style: italic;
  word-wrap: break-word;
}
.scientific-nameheader-text {
  font-size: 2rem;
  word-wrap: break-word;
}

@media(max-width: 991px) {
  .scientific-nameheader-container {
    flex-direction: column;
    margin-top: -80px;
  }
  .scientific-nameheader-heading {
    text-align: center;
  }
  .scientific-nameheader-text2 {
    text-align: center;
  }
  .scientific-nameheader-text {
    text-align: center;
  }
}
@media(max-width: 767px) {
  .scientific-nameheader-heading {
    text-align: center;
  }
}

@media(max-width: 479px) {
  .scientific-nameheader-container {
    font-size: 1.5rem;
  }

  .scientific-nameheader-text2 {
    font-size: 1.5rem;
  }

  .scientific-nameheader-text {
    font-size: 1.5rem;
  }
}
