.detail-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  /* background-image: linear-gradient(0deg, rgb(255, 255, 255) 89.00%,rgb(20, 67, 114) 89.00%); */
}
.detail-research-content {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.detail-separator {
  width: 100px;
  height: 2px;
  background-color: var(--dl-color-gray-500);
}
.detail-container1 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  box-shadow: 5px 5px 10px 0px #d4d4d4;
  margin-top: var(--dl-space-space-twounits);
  align-items: center;
  border-radius: var(--dl-radius-radius-radius8);
  flex-direction: row;
  justify-content: space-between;
}
.detail-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  margin-top: var(--dl-space-space-twounits);
  align-items: space-between;
  margin-left: var(--dl-space-space-fourunits);
  margin-right: var(--dl-space-space-fourunits);
  margin-bottom: var(--dl-space-space-twounits);
  flex-direction: column;
}
.detail-image {
  flex: 1;
  width: 260px;
  height: 336px;
  align-self: center;
  object-fit: fill;
  flex-shrink: 0;
  border-radius: var(--dl-radius-radius-radius8);
  margin-bottom: var(--dl-space-space-twounits);
  object-position: left;
}
.detail-link {
  color: var(--dl-color-gray-white);
  width: 200px;
  height: auto;
  align-self: center;
  text-align: center;
  text-decoration: none;
  background-color: #144372;
}
.detail-link2 {
  color: var(--dl-color-gray-white);
  width: 200px;
  height: auto;
  align-self: center;
  text-align: center;
  text-decoration: none;
  background-color: #144372;
}
.detail-container3 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.detail-banner {
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-threeunits);
  flex-direction: column;
  justify-content: space-between;
}
.detail-text {
  height: 70px;
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
}
.detail-text3 {
  font-weight: 600;
}
.detail-researcher {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-threeunits);
  max-width: var(--dl-size-size-maxwidth);
  flex-direction: column;
}
.detail-container4 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  grid-gap: var(--dl-space-space-twounits);
  align-items: flex-start;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}
.detail-container5 {
  display: flex;
  align-items: center;
  margin-right: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.detail-container6 {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media(max-width: 991px) {
  .detail-research-content {
    align-items: center;
  }
  .detail-container1 {
    flex-direction: column;
  }
  .detail-image {
    width: 300px;
    height: 300px;
    margin-top: var(--dl-space-space-twounits);
    margin-left: 0px;
  }
  .detail-text {
    text-align: center;
  }
  .detail-researcher {
    flex-direction: column;
  }
  .detail-container4 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
  .detail-container5 {
    margin-right: 0px;
    margin-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .detail-research-content {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .detail-container1 {
    flex-direction: column;
  }
  .detail-banner {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .detail-researcher {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}
@media(max-width: 479px) {
  .detail-text {
    height: auto;
    font-size: 1.8rem;
    text-align: center;
    font-weight: 600;
  }
  .detail-research-content {
    /* padding-top: var(--dl-space-space-twounits); */
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .detail-image {
    width: 250px;
    height: 250px;
  }
  .detail-container3 {
    grid-template-columns: 1fr;
  }
  .detail-banner {
    padding-top: -20px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    margin-bottom: -80px;
  }
  .detail-researcher {
    /* padding-top: var(--dl-space-space-twounits); */
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
@media(max-width: 390px) {
  .detail-research-content {
    /* height: 1967px; */
    /* margin-top: 204px; */
  }
}
