.card-categories-testimonial-card {
  /* width: 266px; */
  height: 291px;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: #fff;
  box-shadow: 5px 5px 10px 0px rgba(18, 18, 18, 0.1);
  align-items: center;
  flex-direction: column;
  background-size: cover;
  justify-content: center;
  background-image: url("/playground_assets/rectangle516237-547-300h.png");
  background-position: center;
  /* margin-bottom: 20px; */
}
.card-categories-testimonial {
  width: 100%;
  height: 100%;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.46);
}
.card-categories-testimonial-card :hover{
  transition: 0.3s;
  box-shadow: 0px 0px 50px gray;
}
.card-categories-text {
  color: #ffffff;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 800;
  margin-bottom: var(--dl-space-space-halfunit);
}
.card-categories-text1 {
  color: #d6d5d5;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
  text-transform: uppercase;
}
.card-categories-root-class-name {
  background-size: cover;
  background-image: a98d9d99-aa13-4936-93c1-c0f406ff35e4;
}
.card-categories-root-class-name1 {
  background-size: cover;
  background-image: 71f34166-852f-4153-ad4d-f1f12260e2d4;
}
.card-categories-root-class-name2 {
  background-size: cover;
  background-image: 50d737bc-4ae5-4166-be8c-7a1c90806e3a;
  background-repeat: no-repeat;
  background-position: center;
}
.card-categories-root-class-name3 {
  background-size: cover;
  background-image: 50d737bc-4ae5-4166-be8c-7a1c90806e3a;
  background-repeat: no-repeat;
  background-position: center;
}
.card-categories-root-class-name4 {
  background-size: cover;
  background-image: a98d9d99-aa13-4936-93c1-c0f406ff35e4;
}
.card-categories-root-class-name5 {
  background-size: cover;
  background-image: 71f34166-852f-4153-ad4d-f1f12260e2d4;
}
.card-categories-root-class-name6 {
  background-size: cover;
  background-image: url("/playground_assets/rectangle526240-bw9k-200h.png");
}
.card-categories-root-class-name7 {
  background-size: cover;
  background-image: url("/playground_assets/rectangle52i143-00m-200h.png");
}
.card-categories-root-class-name8 {
  background-size: cover;
  background-image: url("/playground_assets/rectangle52i143-h7ql-200h.png");
}
.card-categories-root-class-name9 {
  background-size: cover;
  background-image: url('https://scx2.b-cdn.net/gfx/news/hires/2023/skim-stone.jpg');
}









@media(max-width: 1000px) {
  .card-categories-testimonial-card {
    background-color: rgba(0, 0, 0, 0.25);
  }
  .card-categories-testimonial {
    /* width: 268px; */
    justify-content: center;
  }
  .card-categories-text {
    color: #ffffff;
    font-style: normal;
    font-weight: 800;
  }
  .card-categories-text1 {
    color: #ffffff;
  }
  .card-categories-root-class-name {
    background-size: cover;
    background-color: transparent;
    background-image: a98d9d99-aa13-4936-93c1-c0f406ff35e4;
  }
  .card-categories-root-class-name1 {
    background-size: cover;
    background-color: transparent;
    background-image: 71f34166-852f-4153-ad4d-f1f12260e2d4;
  }
  .card-categories-root-class-name4 {
    background-size: cover;
    background-color: transparent;
    background-image: a98d9d99-aa13-4936-93c1-c0f406ff35e4;
  }
  .card-categories-root-class-name5 {
    background-size: cover;
    background-color: transparent;
    background-image: 71f34166-852f-4153-ad4d-f1f12260e2d4;
  }
}
@media(max-width: 991px) {
  .card-categories-text1 {
    align-self: center;
  }
}
@media(max-width: 767px) {
  .card-categories-testimonial-card {
    /* padding-left: var(--dl-space-space-twounits); */
    /* padding-right: var(--dl-space-space-twounits); */
  }
}
@media(max-width: 479px) {
  .card-categories-testimonial-card {
    /* padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits); */
  }
  .card-categories-text {
    margin-bottom: var(--dl-space-space-halfunit);
  }
  .card-categories-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
}
